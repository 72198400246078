import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/resume.min.css';

import TheCv from './components/Thecv';


function App() {
  return (
    <div className="App">

    <TheCv />
    </div>
  );
}

export default App;
